.p5-wrap {
    margin: 0 auto;
    display: flex;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 0;
}

/* .p5-wrap:hover {
    filter: invert(1);
} */