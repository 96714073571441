
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&family=VT323&display=swap');

:root {
    --contact1: #000;
    --contact2: #2A1A1F;
    --contact3: #764134;
    --contact4: #AD8350;
    --contact5: #AFA060;
    --contact6: #3D3522;

    --nodeStreak: #60af74;
    --computationalFogTop: #764134dd;
    --computationalFogBot: #764134dd;
    --visualDistortionZapper: 360px;
    --nanoVelocity: 2s;
}


/* #contact {
    background: var(--contact1);
    background: linear-gradient(to left, 
    var(--contact6), 
    var(--contact2), 
    var(--contact3), 
    var(--contact4), 
    var(--contact5), 
    var(--contact5), 
    var(--contact4), 
    var(--contact3), 
    var(--contact2), 
    var(--contact6)
    );
    height: 50vh;
} */

#contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
    width: 100%;
    height: 68vh;
    background-color: var(--contact2);

    overflow: hidden;
}

.app_contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    overflow: hidden;
}

.bar-wrapper {
    display: flex;
    flex-direction: column;
    height: 10%;
}

.col-reverse {
    flex-direction: column-reverse;
}

.contact-bar {
    width: 100%;
    height: 20%;
}

.barc1 {
    background: var(--contact5);
}

.barc2 {
    background: var(--contact4);
}

.barc3 {
    background: var(--contact3);
}

.barc4 {
    background: var(--contact2);
}

.barc5 {
    background: var(--contact6);
}

.pos-abs-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contact-info-wrap {
    position:relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    gap: 30px;
}

.contact-info {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: auto; */
    width: auto;
    /* background-color: #000000ad; */


    background: linear-gradient(90deg,#88868b,#a7a2a6 10%,#a09b9f 50%,#8f8d93 75%,#625f60 90%,#4a4645);
    border-radius: 4px;
    border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5));

    /* width: 40%; */
    margin: 0 auto;
    z-index: 2;
}

.contact-info-inside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    margin: 10px;
}

.email-copy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin: 20px;
}

.email-copy-btn {
    color: #fff;
    font-size: 26px;
    text-align: center;
    width: auto;
    height: auto;
    padding: 6px;
    margin: auto;
}

.email-copy-btn:hover {
    border: 3px inset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5));

    color: black;
    background: rgb(255, 136, 0);
}

.bolt-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
}

.bolt {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    /* margin: 10px; */
    /* border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2); */

    filter: drop-shadow(-1px 1px 3px rgba(0,0,0,0.8));    
    /* background: rgb(236,236,236);
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(255, 243, 149) 38%, rgb(121, 121, 121) 93%); */
    background: url(../../public/images/usedrivot.webp);
    background-position: center;
    background-size: contain;
}

.screw {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    /* margin: auto; */
    /* margin: 10px; */
    /* border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2); */

    filter: drop-shadow(-1px 1px 4px rgba(0,0,0,.5));    
    /* background: rgb(236,236,236);
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(255, 243, 149) 38%, rgb(121, 121, 121) 93%); */
    background: url(../../public/images/screwhead.png);
    background-position: center;
    background-size: contain;
}



h2 {
    color: gold;
    font-weight: 300;
    font-family: 'VT323', monospace;    /* background-color: #000; */
    width: auto;
    font-size: 2.75rem;
    padding: 2px 10px;
    border-radius: 4px;
    
    text-shadow: 0 0 4px #fff7ad86, 2px 2px 16px hsla(0,0%,100%,.5);
    border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5));
    
    /* background: linear-gradient(90deg,rgba(255,0,0,.06),rgba(0,255,0,.02),rgba(0,0,255,.06)); */
    background-image: repeating-radial-gradient(circle at 17% 32%, rgba(139, 139, 139, 0.596), rgba(0, 0, 0, 0.733) 0.00085px);
    background-color: black;


    margin: 12px 5px;

    animation: tv-static 60s linear infinite;
    /* background-image: url(../../public/images/light-aluminum-comp.webp); */
}

.copiedText {
    background: none;
    text-shadow: 0 0 8px #00000086, 2px 2px 16px hsla(0,0%,100%,.5);
    animation: 2s textCopied ease-in-out;
    color: rgb(255, 219, 18);
}

@keyframes textCopied {
    0% {
        background-color: black; 
    }
    12% {
        background-color: #61dd80;
    }
    100% {
        background-color: #61dd80;
    }
}

h3 {
    background: #000;
    border-radius: 4px;
    color: white;
    font-weight: 300;
    font-size: 1.75rem;
    font-family: 'Share Tech Mono', monospace;    /* background-color: #000; */
    padding: 6px 10px 4px 10px;
    text-shadow: 0 0 6px #5e5e5ea6, 2px 2px 16px hsla(0, 0%, 100%, 0.35);

    border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-1px 2px 0 rgba(0,0,0,.5));
    /* background: linear-gradient(90deg,rgba(255,0,0,.06),rgba(0,255,0,.02),rgba(0,0,255,.06)); */
    background-image: repeating-radial-gradient(circle at 17% 32%, rgba(146, 146, 146, 0.596), black 0.00085px);
    
    background-color: black;

    margin: 12px 5px;

    animation: tv-static 60s linear infinite;
    /* animation-play-state: paused; */
}

h3:hover {
    animation-play-state: paused;
    background-color: black;
    background-image: linear-gradient(90deg,rgba(255,0,0,.06),rgba(0,255,0,.02),rgba(0,0,255,.06));
    background-size: 100% 2px,3px 100%;
}

#email {
    color:cadetblue;
    text-decoration: underline;
    width: 285px;
}


@keyframes tv-static {
    from {
      background-size: 100% 100%;
    }
  
    to {
      background-size: 200% 200%;
    }
  }

@media only screen and (max-width: 580px) {
    h2 {
        color: gold;
        font-weight: 300;
        letter-spacing: 2px;
        font-size: 2.5rem;
    }
    
    h3 {
        color: white;
        font-weight: 300;
        letter-spacing: 2px;
        font-size: 1.9rem;
    }
    
    #email {
        width: auto;
        font-size: 1.2rem;
        overflow-wrap: break-word;
    }
    
    .email-copy {
        flex-wrap: wrap;
        margin: 10px 0;
    }
    
    
    .contact-info {
        max-width: 92vw;
    }
}

@media only screen and (max-width: 480px) {
    h2 {
        color: gold;
        font-weight: 300;
        letter-spacing: 2px;
        font-size: 2rem;
  }
  
  h3 {
      color: white;
      font-weight: 300;
      letter-spacing: 2px;
      font-size: 1.9rem;
  }
}


@media only screen and (max-width: 400px) {
    h2 {
        color: gold;
        font-weight: 300;
        letter-spacing: 2px;
        font-size: 1.9rem;
    }
    
    h3 {
        color: white;
        font-weight: 300;
        letter-spacing: 2px;
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 330px) {
    #email {
        overflow-wrap: break-word;
    }
}

@media only screen and (max-height: 875px) {
    #contact {
        height: 100vh;
    }
}




/* html {
    height: 100%;
    overflow: hidden;
} */

.contact-outer {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;

    background: url(../../public/images/cementoverlay.png);

    background: black;

    background-size: cover;
    background-position: center;
}

.brickframe {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0 auto;
    left:0;
    top: 0;

    opacity: 1;

    background: url(../../public/images/papertexture.png);
    filter: sepia(0.75);
    background-size:contain;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    opacity: 1;
    filter: invert(1);

    background: url(../../public/images/dirtoverlay.png);
    background-size: cover;
    /* background-position: center; */
}


.contact-outer-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0 auto;
    perspective: var(--visualDistortionZapper);
    perspective-origin: 50% 50%;  
    animation: filterContact ease-in-out 7s infinite alternate;
    background: linear-gradient(var(--computationalFogTop) 30%, var(--nodeStreak) 50%, var(--nodeStreak) 50%, var(--computationalFogBot) 70%);  
}

@keyframes filterContact {
    0% {
        filter: none;
    }
    75% {
        filter: none;
    }
    80% {
        filter: invert(1);
    }
    100% {
        filter: none;
    }
}

.top-plane {
    width: 200%;
    height: 130%;
    position: absolute;
    bottom: -30%;
    left: -50%;
    background-image: -webkit-linear-gradient(var(--nodeStreak) 2px, transparent 2px), -webkit-linear-gradient(left, var(--nodeStreak) 2px, transparent 2px);
    background-size: 100px 100px,100px 100px;
    background-position: -1px -1px,-1px -1px;
    transform: rotateX(85deg);
    animation: planeMoveTop var(--nanoVelocity) infinite linear;
}

.bottom-plane {
    width: 200%;
    height: 130%;
    position: absolute;
    bottom: -30%;
    left: -50%;
    background-image: -webkit-linear-gradient(var(--nodeStreak) 2px, transparent 2px), -webkit-linear-gradient(left, var(--nodeStreak) 2px, transparent 2px);
    background-size: 100px 100px,100px 100px;
    background-position: -1px -1px,-1px -1px;
    transform: rotateX(-85deg);
    top: -30%;
    animation: planeMoveBot var(--nanoVelocity) infinite linear;
}

@keyframes planeMoveTop {
    /* from {
        background-position: 0px -100px,0px 0px;
    }
    to {
        background-position: 0px 0px, 100px 0px;
    } */
    from {
        background-position: 0px -100px,0px 0px;
    }
    to {
        background-position: 0px 100px, 0 0;
    }
}

@keyframes planeMoveBot {
    /* from {
        background-position: 0px 0px,0px 0px;
    }
    to {
        background-position: 0px -100px, 100px 0px;
    } */
    from {
        background-position: 0px 100px,0px 0px;
    }
    to {
        background-position: 0px -100px, 0px 0px;
    }
}

@media (max-height: 350px) {
    .wrap {
        perspective: var(--visualDistortionZapper) - 150;
    }
}

.contact-info:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    
    background-image: url(../../public/images/metal-texture.webp);
}

.contact-info:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    z-index: -1;
    background-image: url(../../public/images/metalbg-texture.webp);
}

@media (max-height: 350px) {
    .wrap {
        perspective: var(--visualDistortionZapper) - 150;
    }
}

@media only screen and (max-width: 700px) {
    #contact, h3, h2, .contact-outer-wrap, .top-plane, .bottom-plane {
        animation: none;
    }

    h3 {
        background: linear-gradient(90deg, rgb(30, 0, 0), rgb(0, 30, 0), rgb(0, 0, 30));
    }
}