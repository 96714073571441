@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

#portfolio {
    border-top: gold 4px solid;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    /* background: url("../../public/images/pixelrain.jpg"); */
    background: #445e4d;
    background-image: url(../../public/images/topography.png);
    /* background-image: radial-gradient(#ffffff 0px, transparent 1.5px); */
    background-size: 600px 600px;
    background-repeat: repeat;
    
    /* background-attachment: fixed; */
    /* background-size:contain; */
    /* height: 100vh; */
    min-height: 100vh;
    max-height: fit-content;
    position: relative;
}

.magnet-left {
    margin-right: auto;
}

.magnet-right {
    margin-left: auto;
}

.portfolio-block {
    margin: 70px auto 40px auto;
    height: 100%;
    max-height: fit-content;
    width: 97%;
    /* background: linear-gradient(#556b2fc4, #502f6bc4); */
    text-align: center;
}

.port-header {
    font-family: 'VT323', monospace;
    /* font-family: 'Courier New', monospace; */
    font-weight: 400;
    font-size: 4em;
    background-color: #000000;
    border: 6px inset #ffffff;
    box-shadow: 0px 0px 12px #2e0000;
    padding: 20px 30px;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 10px;
    width: fit-content;
    max-width: fit-content;

    /* text-shadow: 0 0 4px #860000, 0px 1px 12px hsla(0,0%,100%,.5); */
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    /* background: #000; */
    background-image: linear-gradient(#4b2121 2px, transparent 2px), 
    linear-gradient(90deg, #4b2121 2px, transparent 2px);
    background-size: 0.4em 0.4em;
    background-position: -10% -10%;
    /* max-width: 100%; */
}
.port-header::before {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #353535;
    filter: blur(3px);
}


.myprojs-wrap {
    display: flex;
    flex-direction: row;
    margin: auto 0;
    background: #282A36;
    padding: 7px 10px;
    border: 4px solid #9da2be;
    border-radius: 4px;
    box-shadow: 0px 0px 6px #9da2be;
}

.legend-wrapper {
    display: flex;
    flex-direction: column;
}

.icon-legend {
    font-family: "VT323", monospace;
    color: #7fff00;
    text-shadow: #7fff00 0px 0px 6px;
    font-size: 1.4rem;
}

.legend-items {
    margin: 8px auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.icon-legend-item {
    display: flex;
    flex-direction: row;
    font-family: 'VT323', monospace;
    letter-spacing: 2px;
    font-size: 1.5rem;
    color: #ffffff;
    background: #000;
    /* background-image: linear-gradient(#ff6e6e33 2px, transparent 2px), 
    linear-gradient(90deg, #ff6e6e33 2px, transparent 2px);
    background-size: 0.5em 0.5em;
    background-position: -10% -10%; */

    background-color: #31000a;
    background-image: url(../../public/images/metal-texture.webp);
    border: 3px inset #a8a8a8;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 10px;
}

.icon-legend-btn {
    margin: auto;
}

.legend-btn-title {
    margin: auto;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0px 3px;

    background: #000;
    background-image: linear-gradient(#2e2e2e 2px, transparent 2px), 
    linear-gradient(90deg, #2e2e2e 2px, transparent 2px);
    background-size: 0.5em 0.5em;
    background-position: -10% -10%;
    border: 3px inset #818181;
}

.legend-icon-span {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    padding: 2px;
    /* height: auto; */
    /* width: 35px;
    height: 35px; */
    /* background-color: #000000ad; */


    background: linear-gradient(90deg,#88868b,#a7a2a6 10%,#a09b9f 50%,#8f8d93 75%,#625f60 90%,#4a4645);
    border-radius: 4px;
    border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5));

    /* width: 40%; */
    margin: auto auto auto 10px;
    z-index: 2;
}

.legend-icon-span:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    
    background-image: url(../../public/images/metal-texture.webp);
}

.legend-icon-span:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    z-index: -1;
    background-image: url(../../public/images/metalbg-texture.webp);
}

.header-spanelement {
    color: rgb(198, 198, 198)
}

.header-spancompo {
    color: rgb(135, 250, 166);
}

.header-spanslash {
    color:#ff6164;
}

.header-spanprops {
    color: lightblue;
    font-style: italic;
}

.projects-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    margin: 0 auto;
    width: 90%;
    height: auto;
    /* background: linear-gradient(#556b2fc4, #502f6bc4); */
    border-radius: 10px;
}


.project-list {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
}

.projects-wrapper::before {
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: fit-content;
    /* background-image: url("../../public/images/pixelspace.jpg"); */
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: contain;
    opacity: 0.3;
    filter: invert(.75);
    border-radius: 10px;
}

.project-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    padding: 10px 5px 10px 5px;
    width: fit-content;
    height: auto;    
    /* background: linear-gradient(45deg, #888888c4 0%, #888888c4 50%, #c175ffcb, #ffe54fcb 50%, #ff4f84cb 60%, #4fd6ffcb 100%);     */
    background: #828282a6;
    /* background: linear-gradient(90deg, #88868b, #a7a2a6 10%, #a09b9f 50%, #8f8d93 75%, #625f60 90%, #4a4645); */
    background-repeat: repeat-x;
    border-radius: 5px;

    transition: 0.5s ease-in-out;
    background-size: 150%;
    transition: background-position .2s cubic-bezier(.47, .1, 1, .63),
    color .2s linear;
    transition: 0.5s ease-in-out;
    overflow: hidden;

    /* background: linear-gradient(90deg,#88868b,#a7a2a6 10%,#a09b9f 50%,#8f8d93 75%,#625f60 90%,#4a4645); */
    border-radius: 4px;
    border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5));
}


.project-card::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    border-radius: 5px;
    /* background-position: center;
    background-size: cover; */
    /* z-index: -1; */
    opacity: 1;
    
    background: url(../../public/images/metal-texture.webp) center;
}

.project-card::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    border-radius: 5px;
    /* background-position: center;
    background-size: cover; */
    z-index: -1;
    opacity: 0.7;
    
    background: url(../../public/images/cementoverlay.png) center;
}

.project-card-clean {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    padding: 10px 10px 15px 10px;
    width: fit-content;
    height: auto;    
    /* background: linear-gradient(45deg, #888888c4 0%, #888888c4 50%, #c175ffcb, #ffe54fcb 50%, #ff4f84cb 60%, #4fd6ffcb 100%);     */
    background: #0000006e;
    /* background: linear-gradient(90deg, #88868b, #a7a2a6 10%, #a09b9f 50%, #8f8d93 75%, #625f60 90%, #4a4645); */
    background-repeat: repeat-x;
    border-radius: 5px;

    transition: 0.5s ease-in-out;
    background-size: 150%;
    transition: background-position .2s cubic-bezier(.47, .1, 1, .63),
    color .2s linear;
    transition: 0.5s ease-in-out;
    overflow: hidden;

    /* background: linear-gradient(90deg,#88868b,#a7a2a6 10%,#a09b9f 50%,#8f8d93 75%,#625f60 90%,#4a4645); */
    border-radius: 4px;
    border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5));
}


.project-card-clean::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    border-radius: 5px;
    /* background-position: center;
    background-size: cover; */
    /* z-index: -1; */
    opacity: 1;
    
    background: url(../../public/images/metal-texture.webp) center;
}

.project-card-clean::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    border-radius: 5px;
    /* background-position: center;
    background-size: cover; */
    z-index: -1;
    opacity: 0.7;
    
}

.inner-card-wrapper-bolts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

/* .project-card::after { */
.gradient-metal-bg {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    /* background-image: url(../../public/images/metalbg-texture.webp); */
    background: linear-gradient(90deg,#88868b,#a7a2a6 10%,#a09b9f 50%,#8f8d93 75%,#625f60 90%,#4a4645);
    border-radius: 5px;
    z-index: -1;
}

/* .project-card:hover {
    background-position: 75%;
} */

.border-aim {
    --g: 2px;     /* the gap */
    --b: 6px;    /* border thickness*/
    --c: #49f62600;; /* the color */
    /* --c: #4AF626;; the color */


    padding: calc(var(--g) + var(--b));
    --_c: #0000 0 25%, var(--c) 0 50%;
    --_g1: repeating-linear-gradient(90deg ,var(--_c)) repeat-x;
    --_g2: repeating-linear-gradient(180deg,var(--_c)) repeat-y;
    /* background:
        var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
        var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%); */
    background:
        var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
        var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%);
        
    background-size: 200% var(--b),var(--b) 200%;
    border-radius: 5px;
    /* cursor: pointer; */
    filter: grayscale(60%);
    transition: .3s ease-in-out;
}

.border-aim:hover {
    --_p: 75%;
    --c: #4AF626;
    filter: grayscale(0%);
}

.border-aim-clean {
    --g: 2px;     /* the gap */
    --b: 6px;    /* border thickness*/
    --c: #49f62600;; /* the color */
    /* --c: #4AF626;; the color */


    /* padding: calc(var(--g) + var(--b)); */
    --_c: #0000 0 25%, var(--c) 0 50%;
    --_g1: repeating-linear-gradient(90deg ,var(--_c)) repeat-x;
    --_g2: repeating-linear-gradient(180deg,var(--_c)) repeat-y;
    /* background:
        var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
        var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%); */
    background:
        var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
        var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%);
        
    background-size: 200% var(--b),var(--b) 200%;
    border-radius: 5px;
    /* cursor: pointer; */
    /* filter: grayscale(60%); */
    transition: .3s ease-in-out;
}

.border-aim-clean:hover {
    box-shadow: 0px 0px 12px white;
}

.border-aim-btn {
    --gg: 2px;     /* the gap */
    --bb: 4px;    /* border thickness*/
    --cc: rgba(0, 0, 0, 0); /* the color */


    padding: calc(var(--gg) + var(--bb));
    --_cc: #0000 0 25%, var(--cc) 0 50%;
    --_gg1: repeating-linear-gradient(90deg ,var(--_cc)) repeat-x;
    --_gg2: repeating-linear-gradient(180deg,var(--_cc)) repeat-y;
    /* background:
        var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
        var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%); */
    background:
        var(--_gg1) var(--_pp, 25%) 0   ,var(--_gg2) 0    var(--_pp,125%),
        var(--_gg1) var(--_pp,125%) 100%,var(--_gg2) 100% var(--_pp, 25%);
        
    background-size: 200% var(--bb),var(--bb) 200%;
    /* cursor: pointer; */
    transition: .3s ease-in-out;
    border-radius: 5px;

    margin: 10px auto 10px auto;
}

.border-aim-btn:hover {
    --cc: #4AF626;; /* the color */
    --_pp: 75%;
    filter: grayscale(0%);
}


.project-title {
    font-family: 'VT323', monospace;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 2rem;
    color: #4AF626;
    text-shadow: 0 0 3px #4AF626, 2px 2px 16px hsla(0,0%,100%,.5);

    background: #000;
    background-image: linear-gradient(#ff6e6e33 2px, transparent 2px), 
    linear-gradient(90deg, #ff6e6e33 2px, transparent 2px);
    background-size: 0.5em 0.5em;
    background-position: -10% -10%;
    border: 3px inset #ffffff;
    /* outline: 3px inset white; */
    border-radius: 7px;
    padding: 5px 5px 5px 5px;
    text-align: center;
    margin: 0 auto 10px auto;
    width: 100%;
    box-sizing: border-box;
    /* margin-bottom: 10px; */
}

.project-title-clean {
    /* font-family: 'VT323', monospace; */
    font-family:'Times New Roman', Times, serif;
    font-weight: 300;
    letter-spacing: 0px;
    font-size: 1.75rem;
    color: #ffffff;
    text-shadow: 0 0 2px #ffffff, 2px 2px 10px hsla(0,0%,100%,.5);

    /* background: #000; */
    /* background-image: linear-gradient(#ff6e6e33 2px, transparent 2px), 
    linear-gradient(90deg, #ff6e6e33 2px, transparent 2px); */
    /* background-size: 0.5em 0.5em;
    background-position: -10% -10%;
    border: 3px inset #ffffff; */
    /* outline: 3px inset white; */
    border-radius: 7px;
    padding: 5px 5px 5px 5px;
    text-align: center;
    margin: 0 auto 10px auto;
    width: 100%;
    box-sizing: border-box;
    /* margin-bottom: 10px; */
}

.project-info {
    margin: 0 auto 0 auto;
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 100%;
}

.project-info-clean {
    margin: 0 auto 0 auto;
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 100%;
}

.reverse-order {
    flex-direction: row-reverse;
}

.project-info img {
    max-width: 35%;
    margin: auto 0 auto 0;
    max-height: 400px;
    height: 100%;
    object-fit:cover;
    border: 2px solid gold;
}

.project-info-clean img {
    max-width: 35%;
    margin: auto 0 auto 0;
    max-height: 400px;
    height: 100%;
    object-fit:cover;
    border: 2px solid white;
}

.proj-desc-links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    margin: auto 0;
    /* padding: 20px; */
    /* margin: 0px 10px 0 10px; */
}

.proj-desc-links-clean {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    margin: auto 0;
    /* padding: 20px; */
    /* margin: 0px 10px 0 10px; */
}

.proj-desc-links hr {
    border: 3px #ffd579b9 solid;
    /* border-radius: 10px; */
    /* margin: 0 auto; */
}

.proj-desc-links-clean hr {
    border: 3px #ffd579b9 solid;
    /* border-radius: 10px; */
    /* margin: 0 auto; */
}

.proj-desc-links p {
    font-family: "VT323", monospace;
    font-size: 1.25rem;
    color: #ffe656;
    letter-spacing: 1px;
    padding: 8px 10px 10px 10px;
    background: #000000b7;
    /* border-rargba(0, 0, 0, 0.747)0px; */
    /* border: 4px solid black; */
    /* box-shadow: -7px -4px rgba(0, 0, 0, 0.425); */

    
    /* margin: 0 auto 10px auto; */
}

.proj-desc-links-clean p {
    /* font-family: "VT323", monospace; */
    font-family: 'Times New Roman', Times, serif;
    font-size: 1rem;
    color: #ffffff;
    letter-spacing: 1px;
    padding: 8px 10px 10px 10px;
    background: #000000b7;
    /* border-rargba(0, 0, 0, 0.747)0px; */
    /* border: 4px solid black; */
    /* box-shadow: -7px -4px rgba(0, 0, 0, 0.425); */

    
    /* margin: 0 auto 10px auto; */
}

.card-wrapper {
    width: 100%;
    height: 100%;
    margin-top: 10px;
}

.card-wrapper-clean {
    width: 100%;
    height: 100%;
    margin-top: 10px;
}

.tech-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;    /* margin: 10px 0 0 0; */
    background: #fff;
}

.tech-wrapper-clean {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;    /* margin: 10px 0 0 0; */
    background: #fff;
}

.tech-stack {
    /* width:200px; */
    width: 100%;
    height: 100%;
    background-color: #FFF;
}

.tech-stack-clean {
    /* width:200px; */
    width: 100%;
    height: 100%;
    background-color: #FFF;
}

.stack-title {
    background-color: rgb(0, 155, 52);
    font-family: 'VT323', monospace;
    color: white;
    font-size: 20px;
    padding: 10px 0 10px 0;
}

.stack-title-clean {
    background-color: #000000;
    font-family: 'VT323', monospace;
    color: white;
    font-size: 20px;
    padding: 10px 0 10px 0;
}

.stack-info {
    background-color: white;
    height: 100%;
    margin: 0;
}

.stack-info-clean {
    background-color: white;
    height: 100%;
    margin: 0;
}

.stack-item {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    font-family: 'DotGothic16', sans-serif;
    font-size: 14px;
    color: rgb(32, 32, 32);
    padding: 1px 0 4px 0;
    text-align: center;
    font-weight: 600;
    margin: 0 auto 0 auto;
}

.stack-item-clean {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    font-family: 'DotGothic16', sans-serif;
    font-size: 14px;
    color: rgb(32, 32, 32);
    padding: 1px 0 4px 0;
    text-align: center;
    font-weight: 600;
    margin: 0 auto 0 auto;
}

.proj-links {
    /* margin: auto; */
    padding: 10px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #700016;
    background-image: url(../../public/images/metal-texture.webp);
}

.proj-links-clean {
    /* margin: auto; */
    padding: 10px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #000000;
    background-image: url(../../public/images/metal-texture.webp);
}

.proj-button {
    font-size: 2rem;
    color: #00000036;
    padding: 5px;
    text-align: center;
    /* transition: 0.5s; */
    margin: auto;

    /* background: linear-gradient(90deg,#88868b,#a7a2a6 10%,#a09b9f 50%,#8f8d93 75%,#625f60 90%,#4a4645); */
    /* border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5)); */


    background: url(../../public/images/dirtoverlay.png) 100%, linear-gradient(90deg, #f5bd16, #ffcd28);
    border-color: #ff7300;
    border-style: inset;
    border-radius: 4px;
    border-width: 3px 3px 2px 2px;
    filter: brightness(0.7);

    transition: ease-in-out 0.2s;
}

.proj-button:hover {
    /* border: 3px inset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5)); */

    color: #ff3c00af;
    /* background: rgb(255, 136, 0); */
    box-shadow: 0 0 8px #ffc106;
    filter: brightness(1);
}

.proj-button-clean {
    font-size: 2rem;
    color: #ffd579b9;
    padding: 5px;
    text-align: center;
    /* transition: 0.5s; */
    margin: auto;

    /* background: linear-gradient(90deg,#88868b,#a7a2a6 10%,#a09b9f 50%,#8f8d93 75%,#625f60 90%,#4a4645); */
    /* border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5)); */


    background: url(../../public/images/dirtoverlay.png) 100%, linear-gradient(90deg, #000000, #000000);
    border-color: #ffffff;
    border-style: inset;
    border-radius: 4px;
    border-width: 3px 3px 2px 2px;
    filter: brightness(0.7);

    transition: ease-in-out 0.2s;
}

.proj-button-clean:hover {
    /* border: 3px inset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5)); */

    color: #ffd579;
    /* background: rgb(255, 136, 0); */
    box-shadow: 0 0 8px #ffffff;
    filter: brightness(1);
}

.main-card {
    background-color: black;
    width: 250px;
    height: 175px;
}

.stack-card-overlay {
    width: 130px;
    height: 85px;
    margin-left: 200px;
    margin-top: -30px;
    background-color: bisque;
}


.programming-art {
    background-color: red;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.proj-btn {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: auto; */
    width: 50px;
    height: 50px;
    /* background-color: #000000ad; */


    background: linear-gradient(90deg,#88868b,#a7a2a6 10%,#a09b9f 50%,#8f8d93 75%,#625f60 90%,#4a4645);
    border-radius: 4px;
    border: 3px outset hsla(300,5%,79%,.75);
    outline: 2px solid rgba(0,0,0,.5);
    box-shadow: -2px 2px 1px rgba(0,0,0,.75), inset 0 0 4px #cbc6cb, 4px 4px 0 rgba(0,0,0,.75);
    filter: drop-shadow(-2px 4px 0 rgba(0,0,0,.5));

    /* width: 40%; */
    margin: 0 auto;
    z-index: 2;
}

.proj-btn-link {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: auto; */
    width: 55px;
    height: 55px;
    /* background-color: #000000ad; */

    /* width: 40%; */
    margin: 0 auto;
    z-index: 2;
}

.proj-btn-link:hover {

}

.proj-btn:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    
    background-image: url(../../public/images/metal-texture.webp);
}

.proj-btn:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    z-index: -1;
    background-image: url(../../public/images/metalbg-texture.webp);
}


@media screen and (min-width: 720px) {
    .projects-wrapper {
        max-width: 75%;
    }
}

@media screen and (min-width: 1250px) {
    .port-header {
        width: 70%;
    }
}

@media screen and (max-width: 1250px) {
    .proj-desc-links p {
        font-size: 1rem;
        /* font-size: 10px; */
        width: auto;
    }

    .projects-wrapper {
        flex-direction: column;
    }

    .project-list {
        margin: 0 auto;
        width: auto;
    }
}

@media screen and (max-width: 700px) {
    .border-aim {
        filter: none;
    }

    .proj-button {
        color: #ff3c00af;
        /* background: rgb(255, 136, 0); */
        box-shadow: 0 0 8px #ffc106;
        filter: brightness(1);
    }
}

@media screen and (max-width: 580px) {
    .portfolio-block {
        margin-left: 0;
        margin-right: 0;
    }

    .border-aim {
        margin: 10px 5px;
        /* pointer-events: none; */
        background: none;
        transition: none;
        padding: 0;
    }

    .projects-wrapper {
        width: 100%;
    }

    .proj-desc-links p {
        font-weight: 400;
        /* font-size: 10px; */
        width: auto;
    }

    .proj-links {
        flex-direction: row;
        justify-content: center;
    }

    .proj-btn {
        margin: auto 5px;
    }

    .project-info img {
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 35%;
    }

    .tech-wrapper {
        flex-direction: column;
    }

    .tech-stack {
        margin: 0 auto;
        padding: 0 0 10px 0;
    }

    .project-title {
        font-size: 1.5rem;
    }

    .project-card:hover {
        background-position: inherit;
    }
}


@media screen and (max-width: 580px) {
    .project-info {
        flex-direction: column-reverse;
    }

    .project-info img {
        margin: auto;
        max-width: none;
        width: 100%;
    }

}

@media screen and (max-width: 500px) {
    .port-header {
        max-width: 85%;
        width: 85%;
        font-size: 3em;
        padding: 20px 10px;
    }

    .icon-legend-item {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 400px) {
    .port-header {
        max-width: 85%;
        width: 85%;
        font-size: 2.6em;
    }

    .legend-btn-title {
        font-size: 1.1rem;

    }

    .icon-legend-span {

    }
}





































.front-end-projects {
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-color: #F2E8CF;
    border: 8px solid #BC4749;
    width: 90%;
    height: fit-content;
    border-left: none;
    border-radius: 0 30px 30px 0;
    position: relative;
    transition: 0.5s;
    /* transform-origin: top left; */
    transform-origin: left;
}

.back-end-projects {
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-color: #F2E8CF;
    border: 8px solid #BC4749;
    border-right: none;
    width: 90%;
    height: fit-content;
    border-radius: 30px 0 0 30px;
    position: relative;
    transition: 0.5s;
    margin-left: auto;
    transform-origin: right;
}

.project-section-label-fe {
    padding: 20px;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 1.75rem;
    font-weight: 400;
    color: #BC4749;
}

.project-section-label-be {
    padding: 20px;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 1.75rem;
    font-weight: 400;
    color: #BC4749;
    text-align: right;
}

.label-container {
    width: 100%;
    height: fit-content;
    position: relative;
    transition: 0.5s;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.label-bar-fe {
    width: 100%;
    border: 3px solid #BC4749;
    height: 0;
    margin: auto 0 auto 0;
    border-radius: 10px 0 0 10px;
}

.label-bar-be {
    width: 100%;
    border: 3px solid #BC4749;
    height: 0;
    margin: auto 0 auto 0;
    border-radius: 0 10px 10px 0;
}

.front-end-projects:hover {
    scale: 1.01;
    background-color: white;
}

.back-end-projects:hover {
    scale: 1.01;
    background-color: white;
}

.projects-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.project-card-img {
    max-width: 250px;
    max-height: 250px;
}

