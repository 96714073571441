.navbar-top {
  font-family: 'VT323', monospace;
  font-size: 24px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
}

nav ul {
  /* text-align: center; */
    display: flex;
    justify-content: center;
    list-style-type: none;

    margin: 0;
	 padding: 5px 7px 5px 7px;
	 border: none;
	 outline: none;
  }

  .solidbg {
    background-color: rgba(0, 0, 0, 0.664);
  }
  
  nav li {
    margin: 0 20px;
    color: #fff;

    margin: 0;
	 padding: 5px 40px 5px 40px;
	 border: none;
	 outline: none;
  }

  nav li a {
    display: inline-block;
   padding: 8px 13px;
   line-height: 32px;
	text-decoration: none;
   text-align: left;

	-webkit-transition: color .2s ease-in-out;
	-moz-transition: color .2s ease-in-out;
	-o-transition: color .2s ease-in-out;
	-ms-transition: color .2s ease-in-out;
	transition: color .2s ease-in-out;

    color: #fff;
  }

  nav li a, nav li a:visited {
    text-decoration: none;
    outline: 0;
    color: #fff;
 
    -webkit-transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
 }
 a:hover, a:focus { color: #fff; }
  
  nav li.active a {
    /* background-color: transparent !important; */
   color: #ffec00; 
  }

  nav li a:hover {
    transition: 0.25s ease-in-out;
    color: #ffec00; 
  }
/* 
  nav li a:hover nav li a::before {
    content: ".";
    display: inline-block;
    color: aqua;
    position: absolute;
    margin-left: -0.5rem;
  }
  nav li a:hover nav li a::after {
    content: "()";
    display: inline-block;
    color: rgb(218, 112, 202);
    position: absolute;
  } */



  nav li.active a::after { 
    padding-top: 0;
    border-bottom:3px solid #ffec00;
    display: block;
    content: "";
    width: 100%;
    translate: -1.5px;
    /* transition: 1s ease; */
  }


.app__navbar-smallscreen {
    display: none;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: rgba(0, 0, 0, 0.8);
  padding: 0.4rem;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    transition: 0.5s ease-in-out;
    flex-direction: column;
    /* z-index: 5; */
}

.app__navbar-smallscreen_overlay .overlay__close {
    color: #fff;
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
}

.app__navbar-smallscreen_links li {
    margin: 1rem;
    cursor: pointer;
    color: white;
    font-size: 2rem;
    text-align: right;
}

.app__navbar-smallscreen_links li:hover {
    color: #ffec00;
}

.overlay__close {
  color: #fff;
}

  @media only screen and (max-width: 650px)  {
    .navbar-top ul {
      display: none;
    }

    .app__navbar-smallscreen {
      position: fixed;
      display: flex;
      right: 0;
      margin: 20px
     }

    
  }