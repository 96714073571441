@font-face {
  font-family: Dot Font;
  src: url(./fonts/dotfont.ttf) format('truetype');
}

@font-face {
  font-family: Fake Receipt;
  src: url(./fonts/fakereceipt.ttf) format('truetype');
}

.testitle {
  font-family: Fake Receipt, monospace;
  font-size: 24px;
  color: chartreuse;
  text-shadow: 0px 0px 7px chartreuse;
  width: 100%;
  text-align: center;
  translate: 0 -32px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 5;
}

.concept-app {
  background: black;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App::-webkit-scrollbar {
  display: none;
}

.marquee-inner {
  display: flex;
  flex-direction: row;
  width: 200%;
  height: 50px;
  font-family: dot font;
  background: repeating-linear-gradient(90deg, #4f46e4 0, #4f46e4 5%, transparent 0, transparent 50%), repeating-linear-gradient(180deg, #4f46e4 0, #4f46e4 5%, transparent 0, transparent 50%);
        background-size: 2em 2em;
        background-color: #000;
        opacity: 1;
  /* position: absolute; */
  overflow: hidden;
  background-color: black;
  margin: auto;
}

.marquee-inner:hover {
  cursor: pointer;
  filter: hue-rotate(280deg) invert(1);
  
  .marquee-left {
      animation-play-state: paused;
      color: rgb(255, 67, 67);
      color: #FFF;
      mix-blend-mode: difference;
      text-shadow: 0 0 4px rgb(255, 67, 67), 2px 2px 16px hsla(0,0%,100%,.5);
  }

  .marquee-right {
      animation-play-state: paused;
      color: rgb(255, 67, 67);
      text-shadow: 0 0 4px rgb(255, 67, 67), 2px 2px 16px hsla(0,0%,100%,.5);
  }
}

.marquee-left {
  color: #fefa12;
  text-shadow: 0 0 4px #fefa12, 2px 2px 16px hsla(0,0%,100%,.5);
  white-space: nowrap;
  float:left;
  width: auto;
  font-size: 48px;
/* 
  background: repeating-linear-gradient(90deg, #4f46e4 0, #4f46e4 5%, transparent 0, transparent 50%), repeating-linear-gradient(180deg, #4f46e4 0, #4f46e4 5%, transparent 0, transparent 50%);
        background-size: 3em 3em;
        background-color: #ffffff;
        opacity: 1; */
  animation: marquee-to-left 20s linear infinite;
}

.marquee-right {
  color: #f476ff;
  text-shadow: 0 0 4px #f476ff, 2px 2px 16px hsla(0,0%,100%,.5);
  white-space: nowrap;
  float:left;
  width: auto;
  animation: marquee-to-left 20s linear reverse infinite;
}

@keyframes marquee-to-left {
  0% {
      transform: translate(0);
  }
  
  100% {
      transform: translate(-100%);
  }
}

.wrapper-border {
  position: absolute; 
  left: 0; 
  right: 0; 
  margin: auto; 
  width: 100%;
  height: 100%;
  z-index: 4;
  box-sizing: border-box;
  outline: #747474 5px solid;
  border: 40px solid black;
  outline-offset: -45px;
  pointer-events: none;
}

.dotted-bg {
  position: absolute; 
  left: 0; 
  right: 0; 
  margin: auto; 
  width: 100%;
  height: 100%;
  background: transparent;
  background-image: radial-gradient(#ffffff9a 0px, transparent 1px);
  background-size: 15px 15px;
  /* background-position: -19px -19px; */
  /* scale: 1.5; */
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
  box-sizing: border-box;
  background-clip: content-box;
  scale: 1.5;
  /* border: transparent 40px solid; */
  padding: 40px;
}

.canvas-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  box-sizing: border-box;
  /* padding: 40px; */
  /* outline: 5px solid rgb(131, 131, 131); */
  /* outline-offset: -40px; */
  /* pointer-events: none; */
}

.tools {
  top: 0;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.431);
  z-index: 2;
  padding: 10px 20px;
}

.toolbar-item {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
  color: chartreuse;
  text-shadow: 0px 0px 7px chartreuse;
}

.main-section-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 45px;
  z-index: 3;
  pointer-events: all;
}

.viewing-pedestal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  pointer-events: all;
}

.green-frostglass {
  border-radius: 20px;
  background: repeating-linear-gradient(90deg, #8f0000 0, #8f0000 2.5%, transparent 0, transparent 50%), repeating-linear-gradient(180deg, #8f0000 0, #8f0000 2.5%, transparent 0, transparent 50%);
        background-size: 3.2em 2em;
        background-color: #80ff0077;
        background-position: -2px ;
  /* filter: blur(3px); */
  box-sizing: border-box;
  box-shadow: 0px 0px 34px chartreuse;
  margin: auto;
  height: 80%;
  width: 85%;
  /* opacity: .5; */
  /* filter: blur(3px); */

  display: flex;
  flex-direction: column;
}

.tiles-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto auto;
  overflow: scroll;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  z-index: 5;

  pointer-events: none;
}

.tiles {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto auto;
  overflow: auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  z-index: 3;

  /* pointer-events: none; */
}

.tiles-wrapper::-webkit-scrollbar {
  display: none;
}

.card-wrap {
  height: 200px;
  width: 150px;
  display: flex;
  flex-direction: column;
  transition: 0.15s ease-in-out;
  filter: invert(1) blur(3px);
  border: black 2px dashed;
  background-color: #0000007c;
  margin: 0px;
  box-sizing: border-box;
  pointer-events: all;
}

.checkbox-card {
  position: absolute;
  translate: 4px 4px;
  cursor: pointer;
  accent-color: #ff8800;
  box-shadow: -0px -0px 4px #000;

  /* filter: invert(1); */
}

.filter-none {
  filter: none;
}

.card-wrap:hover {
  filter: none;
  background-color: #0000008f;
  box-shadow: -2px -2px 10px chartreuse, 2px 2px 10px #ff00ff;
  border: none;
  cursor: pointer;
}

.card-data {
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff9c;
  transition: 0.15s linear;
}

.active-card-data {
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: 0.15s linear;
}

.card-data:hover {
  filter: none;
}

.img-hover {
  transition: 0.2s linear;
}

.active-img-hover {
  transition: 0.2s linear;
}

.img-hover:hover {
  scale: 1.1;
}

.active-img-hover:hover {
  scale: 1.1;
}

.card-title {
  padding: 7px 0;
  font-family: 'Courier New', Courier, monospace;
  text-shadow: 0px 0px 7px chartreuse;
  font-weight: 600;
  color: chartreuse;
  text-align: center;
  background: #ffffff6c;
}
