.windows-programface {
    width: fit-content;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: fit-content;
    margin: 10px;
}

.windows-shadow {
    box-shadow: -7px 4px rgba(0, 0, 0, 0.425);
}

.windows-handle {
    font-family: Microsoft;
    background: rgb(6, 21, 127);
    display: flex;
    user-select: none;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    margin: 0px;
    width: 100%;
    border: 3px solid rgb(177, 177, 177);
}

.w-minimize-btn {
    width: 24px;
    height: 18px;
    background: radial-gradient(orange, red);
    margin: auto 10px auto 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.w-minimize-btn:hover {
    cursor: pointer;
}

.windows-program-wrapper {
    margin: 0;
    /* border-top: 4px solid rgb(177, 177, 177); */
    background-color: #F6F0BC;
    border: 4px solid rgb(177, 177, 177);
    border-top: none;
    overflow: scroll;
}

.w-title-wrap {
    display: flex;
    flex-direction: row;
}

.w-program-icon {
    color: white;
    margin: auto 0 auto 5px;
}

.w-program-title {
    color: white;
    font-size: 14px;

    margin: 7px 0px 7px 5px;
    font-family: Microsoft, 'Courier New', Courier, monospace;
}

@media only screen and (max-width: 415px) {
    .windows-programface {
        max-width: 90vw;
    }
}