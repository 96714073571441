@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Lines&family=Silkscreen&display=swap');

.app-header {
    text-align: center;
    align-content: center;
    background-color: black;
    height: 100vh;
    /* z-index: -2; */
  }

.header-bg {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    /* pointer-events: none; */
}

.header-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px 0 20px;
    z-index: 1;
}

.header-label {
    font-size: 40px;
    color: white;
    font-family: 'VT323', monospace;
    font-weight: 300;
}

.header-name {
    font-size: 4rem;
}

.shineeffect {
    /* font-family: 'Silkscreen'; */
    padding: 0px;
    background: linear-gradient(to right, #ffffff85 0, #ffea4e8e 10%, #ffe46db2 20%, #ffe46db2 30%, #ffea4e8a 40%, #ffffff85 50%, #ffffff85 60%);
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 8s infinite linear;
    color: hsl(0, 0%, 28%);
    /* font-weight: bold!important; */
    letter-spacing: 7px!important;
    text-transform: uppercase;
    z-index: 1;
  }
  
  @keyframes shine {
    0% {
      background-position: 0;
    }
    100% {
      background-position: 800px;
    }
  }

.header-desc {
    color: #4AF626;
    font-size: 2rem;
    font-family: 'VT323', monospace;
    letter-spacing: 2px;
    text-shadow: 0 0 4px #4AF626, 0px 0px 12px hsla(0,0%,100%,.5);
    /* background-color: rgb(0, 71, 0); */
}

.header-type {
    float: left;
    color: #4AF626;
    font-size: 3rem;
    font-family: 'VT323', monospace;
    letter-spacing: 2px;
}

.header-sourcebuttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.header-bar {
    min-width: 35%;
    margin: 18px auto 24px auto;
    border-color: #ffec0080;
    z-index: 1;
}

.header-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header-button {
    height: 40px;
    font-size: 40px;
    color: white;
    padding: 0 15px 0 15px;
    transition: 0.5s;
    margin: 0 10px 0 10px;
}

.header-button:hover {
    color:#4AF626;
}

.aim-btn {
    --gg: 2px;     /* the gap */
    --bb: 4px;    /* border thickness*/
    --cc: rgba(0, 0, 0, 0); /* the color */


    padding: calc(var(--gg) + var(--bb));
    --_cc: #0000 0 25%, var(--cc) 0 50%;
    --_gg1: repeating-linear-gradient(90deg ,var(--_cc)) repeat-x;
    --_gg2: repeating-linear-gradient(180deg,var(--_cc)) repeat-y;
    /* background:
        var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
        var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%); */
    background:
        var(--_gg1) var(--_pp, 25%) 0   ,var(--_gg2) 0    var(--_pp,125%),
        var(--_gg1) var(--_pp,125%) 100%,var(--_gg2) 100% var(--_pp, 25%);
        
    background-size: 200% var(--bb),var(--bb) 200%;
    /* cursor: pointer; */
    border-radius: 5px;
    transition: .3s;
}

.aim-btn:hover {
    /* --bb: 10px; */
    --cc: gold; /* the color */
    --_pp: 75%;
    filter: grayscale(0%);
}

.scrolldown {
    width: 100%;
    color: white;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: absolute;
    bottom: 30px;
    display: block;
    font-size: 42px;
    line-height: 42px;
    border-radius: 100%;
}

.scrolldown-button {
    color: white;
    opacity: 0.85;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.scrolldown-button:hover {
    color:gold;
}

@media only screen and (max-width: 500px) {
    .header-wrapper {
        text-align: left;
    }

    .header-desc {
        font-size: 1.5rem;
    }

    .header-bar {
        width: 100%;
        margin: 18px auto 24px 0px;
    }

    .header-type {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 350px) {
    .header-name {
        font-size: 3.25rem;
    }
}