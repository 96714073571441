.p5-wrap {
    margin: 0 auto;
    display: flex;
    filter: invert(1) sepia(0.5);
    background-color: #BB11AA;
    transition: transform 1s ease;
    -webkit-transition : -webkit-filter 500ms linear;
    border: 3px solid #ffffff;
    border-top: none;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
}

.p5-wrap:hover {
    filter: invert(1);
}