.bumper-wrap {
  font-family: "Dot Font", monospace;
  font-size: 3rem;
  font-size: 35px;
  letter-spacing: 2px;
  /* background-color: #000; */
  background-color:#000;
  background-size: 20px 20px;
  /* background-image:  repeating-linear-gradient(0deg, #3d0000, #3d0000 2px, #000000 1px, #000000); */
  background-image: repeating-linear-gradient(0deg,#000 0%, #000 30%, #630000 50%, #000 70%, #000 100%);
  
  
  /* background-color: rgb(255, 231, 201);
  box-shadow:  -8px -6px #00A4BD, 6px 6px #FF7A59; */
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 200px;
  /* width: 350px; */
  min-width: 250px;
  width: 320px;
  max-width: 320px;
  overflow: hidden;
}

.nobgcolor {
  background: none;
}

#visualizer {
	display: flex;
	flex-direction: column;
	width: 100%;
  height: 100%;
  animation: hue-fade 20s infinite alternate;
}

.not-playing {
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  font-family: 'VT323', monospace;
  color: #eeff00;
  font-size: 28px;
  text-shadow: 0 0 2px #eeff00, 2px 2px 16px hsla(0,0%,100%,.5);
  background: #00000090;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes hue-fade {
  /* from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  } */
}

#controls
{		
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(#a3a3a3,#eeeeee);
  background: #000000;
}

#controls hr {
  color: #FFF;
  width: 24%;
  height: 0;
  border: #FFF 2px dashed;
  margin: 0 auto;
}

#controls button {
  background: none;
  outline: none;
  border: none;
  color: #fff;
  box-sizing: border-box;
  margin: 7px 6px 3px 6px;
  font-size: 24px;
}

#controls button:hover {
  color: #4cffe7;
}

.bars-wrapper {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 1px;
  padding: 10px 0;

  /* animation: alternate 3s blink infinite; */
  /* margin-top: 20px; */
  /* background: linear-gradient(dimgray, rgb(0, 0, 0)); */
}

.bars-wrapper-lynx {
  padding-top: 30%;
}


.bawrap {
  width: 100%;
  height: auto;
}

.flip-down {
  transform-origin: left;
  transform: rotateX(180deg);
}

#bars
{		
  flex: 4;
  display: flex;
  flex-direction: row;
  /* justify-content: end; */
  align-items: center;
  gap: 1px;
  height: 100%;
}

.flip {
  transform: rotateY(180deg);
}

.unflip {
  /* transform: rotateX(180deg); */
}

.bar {	
  width: calc(100% / 32);
  background: #fff;
  background: linear-gradient(
      chartreuse,gold
  );
  box-shadow: 0 0 4px #eeff00, 0px 0px 4px hsla(0,0%,100%,.5);
  /* box-shadow: 0 0 2px #00000098, 1px 1px 16px hsla(0,0%,100%); */
  /* height: 20px; */
  /* display: none; */
  transform-origin: center;

  border-radius: 20px;
}

.bar:hover {
  background: linear-gradient(
      orange, white
  );
  transform: scaleY(1.25);
  
}
.inverted-bar {	
  width: calc(100% / 32);
  background: linear-gradient(
      rgba(128, 255, 0, 0.75)  30%, #ff0000
  );
  box-shadow: 0 0 2px #00000098, 1px 1px 16px hsla(0,0%,100%);
  height: 20px;
  /* display: none; */

  border-radius: 20px;

  transform-origin: bottom;
}

.inverted-bar:hover {
    background: linear-gradient(
        orange, white
    );

    transform: scale(1.25);
}